// Created 2021-9-21 by MANWIT: This is a one-to-one migration of the SymCogJS library to a nearly identical SymFRJS library 
// to ease migration for applications that are already using SymCogJS, as well
// as allows to easily develop OAuth2 clients
/****************************************************************************************************
 *
 *  Name: symfrjs
 *  Purpose: Client-side implementation of ForgeRock authentication and authorization for ICAP SSO
 *
 ****************************************************************************************************/
// Modified 2020-05-26 by MANWIT: After upgrading to Angular9 (with breaking changes) it seems impossible to reference external Typings in a file based dependency, now dumping the whole Typing into the code file
// import { IAuthConfig, IUserAuthorizationItems, IGetNewJWTTokenPayload, IGetRefreshJWTTokenPayload, IJToken } from "../typings/symcogjs.d";
import * as moment from "../moment/moment";
// Disabled 2020-05-26 by MANWIT: Declaring modules doesn't work in Angular 9 anymore due to braking changes introduced
//declare module "form-urlencoded";
//declare module "jwt-decode";
//declare module "moment-timezone";
// Added 2020-06-10 by MANWIT: Adding polyfills here for IE11 compatibility
// IE11: Object doesn't support property or method 'entries'
if (!Object["entries"])
    Object["entries"] = function (obj) {
        var ownProps = Object.keys(obj), i = ownProps.length, resArray = new Array(i); // preallocate the Array
        while (i--)
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
        return resArray;
    };
var symfrjs = /** @class */ (function () {
    function symfrjs() {
        // This is part of the config.json containing the ForgeRock settings
        this.authConfig = null;
        // This is the public key that we can use to validate the token, however that really needs to happen on the server (although even if we would not do it on the server eventually our API calls to the back-end would fail eventually any way - the api back end essentially is our first line of defense in a static website hosting scenario)
        this._userIdTokensPublicKey = null;
        // This is the property bag we will keep our tokens and authorization return objects in
        this.userAuthorizationItems = null;
    }
    // Step 1: Update the AuthConfig from the config.json we deployed with our website and store it in a local attribute
    /**
     * @remarks Update the AuthConfig from the config.json we deployed with our website and store it in a local attribute
     *
     * @param authConfig ForgeRock specific configuration settings
     * @returns nothing
     */
    symfrjs.prototype.updateAuthConfig = function (authConfig) {
        //  console.log('updateAuthConfig = ' + JSON.stringify(authConfig));
        this.authConfig = authConfig;
    };
    // Step 2: Exchange the "code" from the querystring for a new JWT token. This is the code we will receive as querystring parameter "code" after redirect FROM the ForgeRock SSO login page (this method calls getNewJwtTokens())
    /**
     *
     * @remarks Authorize the user after we got a "code" parameter on the querystring
     *
     * @param authorizationCode The "code" querystring parameter we received back from the SSO page
     * @param success The callback being called when we received a valid access token after exchanging the authentication code
     * @param fail The callback being called when the exchange of an authorization code for an access token failed, resulted in an invalid token or resulted in a permission denied
     */
    symfrjs.prototype.authorizeUser = function (authorizationCode, success, fail, code_verifier) {
        // console.log('authorizeUser()');
        var _this = this;
        this.userAuthorizationItems = {};
        // First determine the public key (we may use it to validate the token - but that should happen on the server eventually!)
        this.getPublicKeyForUserIdTokens(function (jsonResultPublicKey) {
            // Success
            // Exchange the code for a real token
            _this.getNewJwtTokens(authorizationCode, function () {
                // Success
                // Process and store the new token
                _this.processNewTokens(function (tokenIsValid) {
                    // Success
                    if (tokenIsValid) {
                        success();
                    }
                    else {
                        fail("Invalid token");
                    }
                }, function (error) {
                    // Error
                    _this.userAuthorizationItems.UserAuthorizedForApplicationAccess = false;
                    fail(error);
                });
            }, function (error) {
                _this.userAuthorizationItems.UserAuthorizedForApplicationAccess = false;
                fail(error);
            }, 
            // Added 2020-07-30 by MANWIT: for implementation of PKCE
            code_verifier);
        }, function (error) {
            fail(error);
        });
    };
    // Modified 2020-07-30 by MANWIT:code_verifier?:string
    // Step 2: Exchange the "code" from the querystring for a new JWT token. This is the code we will receive as querystring parameter "code" after redirect FROM the ForgeRock SSO login page (this method is called by authorizeUser())
    /**
     * @remarks Exchange the "code" from the querystring for a new JWT token. This is the code we will receive as querystring parameter "code" after redirect FROM the ForgeRock SSO login page (this method is called by authorizeUser())
     *
     * @param authorizationCode This is the code we will receive as querystring parameter "code" after redirect FROM the ForgeRock SSO login page
     * @param success This is the callback that will be called when the exchange of an authorization code for an access token was successful
     * @param fail This is the callback that will be called when the exchange of an authorization code for an access token failed
     * @param code_verifier Optional code verifier if PKCE is implemented: https://aws.amazon.com/blogs/mobile/understanding-amazon-cognito-user-pool-oauth-2-0-grants/  (optional, is required if a code_challenge was specified in the original request) – The base64 URL-encoded representation of the unhashed, random string that was used to generate the PKCE code_challenge in the original request.
     *
     * @returns No direct return values. Only a success or an fail callback.
     */
    symfrjs.prototype.getNewJwtTokens = function (authorizationCode, success, fail, code_verifier) {
        //console.log('getNewJwtTokens()');
        var _this = this;
        // Added 2020-08-11 by MANWIT: If we did supply a PKCE challenge, only then are we to include the code_verifier in this request as a proof key
        // In all other cases we ignore the code_verifier, even if we find it in localstorage.
        var usesPKCE = this.authConfig.SSOLoginUrl.indexOf('code_challenge=') > -1;
        var requestValues = {
            "grant_type": "authorization_code",
            // Our ForgeRock app client Id
            "client_id": this.authConfig.AppClientId,
            // The "code" parameter from the querystring
            "code": authorizationCode,
            // Added 2020-07-30 by MANWIT: if a PKCE was implemented apply the code_verifier here: https://aws.amazon.com/blogs/mobile/understanding-amazon-cognito-user-pool-oauth-2-0-grants/
            // Modified 2020-08-11 by MANWIT: do NOT include the code_verifier if there was no code_challenge supplied in the original request to /authorization
            // "code_verifier": (usesPKCE && typeof code_verifier !== "undefined" && code_verifier !== null) ? code_verifier : undefined,
            // The redirect URL we configured in the ForgeRock app client
            // Commented 2020-08-11 by MANWIT: NO need to urlEncode this as we are using JSON.stringify below to submit the payload
            "redirect_uri": this.authConfig.RedirectUrl //,
            //"client_assertion": "urn:ietf:params:oauth:client-assertion-type:jwt-bearer"
        };
        // Added 2020-07-30 by MANWIT: if a PKCE was implemented apply the code_verifier here: https://aws.amazon.com/blogs/mobile/understanding-amazon-cognito-user-pool-oauth-2-0-grants/
        // Modified 2020-08-11 by MANWIT: do NOT include the code_verifier if there was no code_challenge supplied in the original request to /authorization
        if (usesPKCE && typeof code_verifier !== "undefined" && code_verifier !== null) {
            requestValues.code_verifier = code_verifier;
        }
        // console.log('requestValues = ' + JSON.stringify(requestValues));
        // The /oauth2/token wants the parameters in a certain format, namely "&param1=value1&param2=value2" etc.. so convert it that way
        var payload = new URLSearchParams();
        for (var prop in requestValues) {
            //   console.log('prop = ' + prop);
            payload.set(prop, requestValues[prop]);
        }
        // console.log('payload = ' + JSON.stringify(payload.toString()));
        // Added 2020-10-22 by MANWIT: When using custom Authorization Servers, the UserPoolUrl may already contain
        // the full authorization server endpoints including an /oauth2 path. If that is the case
        // we avoid adding /oauth2 before /token
        var adjustedAuthServerUrl = this.authConfig.AuthServerUrl;
        // if (adjustedUserPoolUrl.indexOf('/oauth2') == -1) {
        //     adjustedUserPoolUrl += (adjustedUserPoolUrl.endsWith('/') ? '' : '/') + 'oauth2';
        // }
        adjustedAuthServerUrl = adjustedAuthServerUrl + (adjustedAuthServerUrl.endsWith('/') ? '' : '/') + 'access_token';
        console.log('adjustedAuthServerUrl =' + adjustedAuthServerUrl);
        fetch(adjustedAuthServerUrl, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            body: payload.toString()
        }).then(function (body) {
            return body.json();
        }).then(function (body) {
            console.log('getNewJwtTokens result = ' + JSON.stringify(body));
            // Added 2020-08-11 by MANWIT: if the result containen an error attribute, we have a failure..
            if (typeof body["error"] !== "undefined") {
                fail(body["error"]);
            }
            else {
                // Get and store all the different tokens from the response
                // Added 2021-09-22 by MANWIT: try catch, because we may not always get an id token, rather an access_token and vice versa
                try {
                    _this.userAuthorizationItems.UserIdToken = body["id_token"];
                }
                catch (ex) { }
                ;
                // Added 2021-09-22 by MANWIT: try catch, because we may not always get an id token, rather an access_token and vice versa
                try {
                    _this.userAuthorizationItems.AccessToken = body["access_token"];
                }
                catch (ex) { }
                ;
                // We always will get a refresh_token
                _this.userAuthorizationItems.RefreshToken = body["refresh_token"];
                success();
            }
        }).catch(function (error) {
            // If something went wrong we'll exit with an error, eventually causing a redirect back to the login page when the callbacks bubble up
            console.log('getNewJwtTokens error = ' + JSON.stringify(error));
            fail(error);
        });
    };
    // Step 3: Exchange the refresh token for a new token after it has expired
    /**
     *
     * @remarks Exchange the refresh token for a new token after it has expired
     *
     * @param success Callback that is called when exchanging the refresh token for a new access token succeeded
     * @param fail Callback that is called when exchanging the refresh token for a new access token failed
     *
     * @returns No direct return values, only a success or a fail callback
     */
    symfrjs.prototype.getRefreshJwtTokens = function (success, fail) {
        // console.log('getRereshJwtTokens()');
        var _this = this;
        var requestValues = {
            "grant_type": "refresh_token",
            "client_id": this.authConfig.AppClientId,
            refresh_token: this.userAuthorizationItems.RefreshToken
        };
        //console.log('requestValues = ' + JSON.stringify(requestValues));
        // The /oauth2/token wants the parameters in a certain format, namely "&param1=value1&param2=value2" etc.. so convert it that way
        var payload = new URLSearchParams();
        for (var prop in requestValues) {
            // console.log('prop = ' + prop);
            payload.set(prop, requestValues[prop]);
        }
        //console.log('payload = ' + JSON.stringify(payload.toString()));
        var adjustedAuthServerUrl = this.authConfig.AuthServerUrl;
        // if (adjustedUserPoolUrl.indexOf('/oauth2') == -1) {
        //     adjustedUserPoolUrl += (adjustedUserPoolUrl.endsWith('/') ? '' : '/') + 'oauth2';
        // }
        adjustedAuthServerUrl = adjustedAuthServerUrl + (adjustedAuthServerUrl.endsWith('/') ? '' : '/') + 'access_token';
        console.log('adjustedAuthServerUrl =' + adjustedAuthServerUrl);
        fetch(adjustedAuthServerUrl, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            // Modified 2019-06-07 by MANWIT: typo payload should be body
            body: payload.toString()
        }).then(function (body) {
            return body.json();
        }).then(function (data) {
            // Get the new token. Notice how we are not interested in the refresh token, we already have that one
            //console.log('getRereshJwtTokens result = ' + JSON.stringify(body));
            _this.userAuthorizationItems.UserIdToken = data["id_token"];
            _this.userAuthorizationItems.AccessToken = data["access_token"];
            success();
        }).catch(function (error) {
            //console.log('getRefreshJwtTokens error = ' + JSON.stringify(error));
            fail(error);
        });
    };
    // Step 4: Process and store a new token and extract the user information from the claims inside the token
    /**
     *
     * @remarks Process and store a new token and extract the user information from the claims inside the token
     *
     * @param success A callback called when an access token was received successfully from ForgeRock
     * @param fail A callback called when there was a problem with the access token we received from ForgeRock
     *
     * @returns No direct return value. Only a success and fail callback.
     */
    symfrjs.prototype.processNewTokens = function (success, fail) {
        // console.log('processNewTokens()');
        var _this = this;
        // First validate the token.. (this does not replace validation of the token on the server!) before accepting it as valid
        // Technically we could just assume it is valid and have the back-end deny any access when the application starts calling API endpoints, but it is nicer if we can proactively log out a user or trigger a token refresh
        this.validateToken(function (tokenIsValid) {
            // Success
            if (!tokenIsValid) {
                success(false);
            }
            else {
                // TODO: remove this dependency like this. Currently we are experiencing great trouble trying to make jwt_decode a proper dependency through NPM and Typescript import definitions. 
                // Since jwt_decode doesn't seem to be a properly designed UMD module, we have to add a reference to jwt_decode in the old-fashioned way
                // Make sure we notify the developer if the dependency on the jwt_decode JS library is missing
                if (typeof window["jwt_decode"] == "undefined") {
                    fail("Script dependency missing: jwt_decode.js should be included in your script headers or loaded with a script loaded such as RequireJS");
                }
                else {
                    // If the token is valid, decode it to determine the expiration date, so we can do a refresh before we hit and endpoint that denies us access
                    var decoded_token = null;
                    // Added 2021-09-22 by MANWIT: In ForgeRock we were always passing around ID tokens, we should however be
                    // working with Access Tokens
                    if (_this.userAuthorizationItems.AccessToken !== undefined && _this.userAuthorizationItems.AccessToken != null) {
                        decoded_token = window["jwt_decode"](_this.userAuthorizationItems.AccessToken);
                    }
                    else {
                        decoded_token = window["jwt_decode"](_this.userAuthorizationItems.UserIdToken);
                    }
                    // We need to convert the UTC date back to local to get the right moment the token will expire from client perspective
                    var validToUtc = moment.utc(new Date(decoded_token.exp * 1000));
                    var localValidTo = validToUtc.local().toDate();
                    //  console.log('localValidTo = ' + localValidTo);
                    _this.userAuthorizationItems.ValidTo = localValidTo;
                    _this.userAuthorizationItems.UserAuthorizedForApplicationAccess = true;
                    // Get some data like user id and name from the claim data, as well as the group membership (if any are configured)
                    _this.getClaimData(decoded_token);
                    // Add token to local storage
                    //console.log('Store userAuthorizationItems in LocalStorage');
                    window.localStorage.setItem('userAuthorizationItems', JSON.stringify(_this.userAuthorizationItems));
                    //  console.log('setItem() successful');
                    // Added 2020/03/25 by MANWIT: Moved here from the callback so that this always happens when calling processNewTokens() 
                    _this.userAuthorizationItems.IsUserAuthorized = true;
                    _this.userAuthorizationItems.SaveUserAuthorizationItemsToSession = true;
                    success(true);
                }
            }
        }, function (error) {
            // Error
            fail(error);
        });
    };
    // Step 5: Get AccessToken that will be used for all the HTTP calls to the back-end endpoints, and should be added to the Authorization header of each HTTP call to our back-end
    /**
     *
     * @remarks Get AccessToken that will be used for all the HTTP calls to the back-end endpoints, and should be added to the Authorization header of each HTTP call to our back-end
     *
     * @returns Access token to be applied as Authorization Header to back-end AWS API endpoints
     */
    symfrjs.prototype.getAuthHeaderToken = function () {
        console.log('getAuthHeaderToken()');
        // We return a word EMPTY so that is will become obvious when profiling the network requests, the access token is missing due to Security = false OR due to the fact we weren't able to authenticate successfully
        if (typeof this.userAuthorizationItems == "undefined" || this.userAuthorizationItems == null || (typeof this.userAuthorizationItems.UserIdToken == "undefined" && typeof this.userAuthorizationItems.AccessToken == "undefined") || (this.userAuthorizationItems.UserIdToken == null && this.userAuthorizationItems.AccessToken == null)) {
            return "EMPTY";
        }
        //console.log('getAuthHeaderToken() = ' + this.userAuthorizationItems.AccessToken.toString());
        // Added 2021-09-22 by MANWIT: in ICAP we will be working mostly with access token, as we were incorrectly passing around ID tokens in the Cognito world.
        if (this.userAuthorizationItems.AccessToken !== undefined) {
            return this.userAuthorizationItems.AccessToken;
        }
        else {
            return this.userAuthorizationItems.UserIdToken;
        }
    };
    // Since we are storing tokens in the local storage, look there first if we need a token before reauthenticating...
    /**
     *
     * @remarks Since we are storing tokens in the local storage, look there first if we need a token before reauthenticating...
     *
     * @param success
     * @param fail
     *
     * @returns No direct return values. Only a success or fail callback.
     */
    symfrjs.prototype.tryGetTokenFromLocalStorageIfNeeded = function (success, fail) {
        //console.log('tryGetTokenFromLocalStorage()');
        // Only if we have no tokens in memory, will we try to look for them in the LocalStorage
        if (!this.userAuthorizationItems || !Object.keys(this.userAuthorizationItems).length) {
            if (typeof this.authConfig == "undefined" || this.authConfig == null) {
                console.log('DEVELOPER ALERT! Please have authConfig loaded before performing auth. You may have to move some of your code from the constructor() to ngOnInit() or ngAfterViewInit()');
            }
            else 
            // If we opted out of local storage for tokens, then we'll return immediately 
            if (typeof this.authConfig.EnableLocalStorage !== "undefined" && !this.authConfig.EnableLocalStorage) {
                fail("Skipping retrieval of token from LocalStorage");
            }
            else {
                // console.log('Try getting userAuthorizationItems from LocalStorage');
                // Try get the tokens (that are stored as a string not an object!) from the local storage and parse it as an object
                var userAuthorizationItems = window.localStorage.getItem('userAuthorizationItems');
                //console.log('getItem() = ' + userAuthorizationItems);
                if (userAuthorizationItems !== null) {
                    this.userAuthorizationItems = JSON.parse(userAuthorizationItems);
                    success();
                }
                else {
                    fail("No token found in LocalStorage");
                }
            }
        }
        else {
            // console.log('We still have userAuthorizationItems in memory! = ' + JSON.stringify(this.userAuthorizationItems));
            // We have a token in mem so immediately return with success
            success();
        }
    };
    // Determine if the user is authorized
    /**
     *
     * @remarks Determine if the user is authorized
     *
     * @param refreshExpiredTokens When true, an attempt will be made to refresh an expired token.
     * @param success Called when the user is deemed to be authenticated and authorized to use the application
     * @param fail Called when the user is deemed not to be authenticated or not authorized to use the application
     *
     * @returns No direct return value. Only a success or an err callback resp. indicating successful authentication/authorization or unsuccessful authentication/authorization
     *
     */
    symfrjs.prototype.isUserAuthorized = function (refreshExpiredTokens, success, fail) {
        // console.log('isUserAuthorized()');
        var _this = this;
        if (refreshExpiredTokens === void 0) { refreshExpiredTokens = true; }
        // First try to see if we have a token in mem or can get it from Local Storage
        this.tryGetTokenFromLocalStorageIfNeeded(function () {
            // If we have no tokens stored or the last auth failed, we redirect back to the login page
            if (!_this.userAuthorizationItems || !Object.keys(_this.userAuthorizationItems).length || !_this.userAuthorizationItems.UserAuthorizedForApplicationAccess) {
                fail("Unauthorized");
                return;
            }
            //TODO: convert back from UTC!
            // If the token has expired, refresh them to see if we can avoid having to logon again
            // Modified 2019-06-07 by MANWIT: a string was compared to a Date(), added Date() around the ValidTo
            if ((_this.userAuthorizationItems.ValidTo == null || new Date() > new Date(_this.userAuthorizationItems.ValidTo)) && refreshExpiredTokens) {
                // Do we actually have a refresh token we could use?
                if (_this.userAuthorizationItems.RefreshToken != null) {
                    // Try and refresh the JWT token
                    _this.getRefreshJwtTokens(function () {
                        // Success, we should now have new tokens
                        // Get the public key so we CAN validate the token (although this still needs to happen on the server!!)
                        // TODO: validate token on server
                        _this.getPublicKeyForUserIdTokens(function (jsonResultPublicKey) {
                            _this._userIdTokensPublicKey = jsonResultPublicKey;
                            // Process the new tokens..
                            _this.processNewTokens(function (tokenIsValid) {
                                // Success
                                // In case something went wrong processing the tokens, access could still be denied.
                                if (tokenIsValid) {
                                    _this.userAuthorizationItems.IsUserAuthorized = true;
                                    _this.userAuthorizationItems.SaveUserAuthorizationItemsToSession = true;
                                    success();
                                    return;
                                }
                                else {
                                    _this.userAuthorizationItems.IsUserAuthorized = false;
                                    fail("Token deemed not valid after processing.");
                                    return;
                                }
                            }, function (error) {
                                fail(error);
                                return;
                            });
                        }, function (error) {
                            fail(error);
                            return;
                        });
                    }, function (error) {
                        fail(error);
                        return;
                    });
                }
            }
            else {
                // If token is still valid, we are good to go (even if access is denied, the user wont be able to hit the API endpoints)
                success();
                return;
            }
        }, function (error) {
            fail(error);
        });
    };
    // Get the public key for a token
    /**
     *
     * @remarks Returns the public key for the token which we can use to validate the token (this does NOT replace server side token validation!)
     *
     * @param success A callback that is being called when the retrieval of the public key succeeded
     * @param fail A callback that is being called when retrieval of the public key failed
     *
     * @returns No direct return values. Only a success callback or err callback.
     */
    symfrjs.prototype.getPublicKeyForUserIdTokens = function (success, fail) {
        console.log('getPublicKeyForUserIdTokens()');
        // Modified 2020-10-23 by MANWIT: determine where the well-knows JWKS are
        var authorizationServerJWKSEndpoint = this.authConfig.AuthServerUrl + "/.well-known/jwks.json";
        if (typeof this.authConfig.AuthorizationServerJWKSEndpoint !== "undefined" && this.authConfig.AuthorizationServerJWKSEndpoint != null && this.authConfig.AuthorizationServerJWKSEndpoint.length > 0) {
            authorizationServerJWKSEndpoint = this.authConfig.AuthorizationServerJWKSEndpoint;
        }
        console.log('getPublicKeyForUserIdTokens() authorizationServerJWKSEndpoint = ' + authorizationServerJWKSEndpoint);
        fetch(authorizationServerJWKSEndpoint, null, function (stream) {
            return new Response(stream);
        }).then(function (response) { return response.json(); }).then(function (body) {
            console.log('getPublicKeyForUserIdTokens result = ' + JSON.stringify(body));
            var jsonResultPublicKey = body["keys"][0].toString();
            success(jsonResultPublicKey);
        }).catch(function (error) {
            fail(error);
        });
    };
    // TODO: move this to the back-end
    /**
     *
     * @remarks Validates the token using the public key. This does not replace server side token validation which still needs to take place!
     *
     * @param success Called when the token was successfully validated
     * @param fail Called when an error occured during token validation
     */
    symfrjs.prototype.validateToken = function (success, fail) {
        // console.log('validateToken()');
        //TODO: For DEV, assume token is valid
        success(true);
    };
    // Get some data from the claims in the token such as user id, name, email, group membership, etc.
    /**
     *
     * @remarks Returns claims/user information from a decoded JWT token (use a JS library such as jwt_decode to decode a JWT)
     *
     * @param userIdJwtToken A decoded JWT token (use a JS library such as jwt_decode to decode a JWT)
     *
     * @returns No direct return value. All claim/user data will be stored as attributes to this service class
     */
    symfrjs.prototype.getClaimData = function (userIdJwtToken) {
        // Example data: {"at_hash":"SB9tNshuUd8yeJYGrWXVOA","sub":"efd5f91d-90a4-43fe-8992-0d2598f6886c","cognito:groups":["us-west-2_8bZcaS1Kh_SymetraADDev"],"email_verified":false,"iss":"https://cognito-idp.us-west-2.amazonaws.com/us-west-2_8bZcaS1Kh","cognito:username":"SymetraADDev_MANWIT@corp.symetra.com","aud":"677hoalf9hdd5lstfj9civa7mg","identities":[{"userId":"MANWIT@corp.symetra.com","providerName":"SymetraADDev","providerType":"SAML","issuer":"https://sts.windows.net/4e5f16d4-9659-4006-a4f6-331b0cb8fa51/","primary":"true","dateCreated":"1548778630791"}],"token_use":"id","auth_time":1556305198,"exp":1556308798,"iat":1556305198,"email":"Manfred.Wittenbols@symetra.com"}
        // console.log('getClaimData() = ' + JSON.stringify(userIdJwtToken));
        this.userAuthorizationItems.UserId = this.getUserId(userIdJwtToken);
        this.userAuthorizationItems.UserName = this.getUserNameFromEmailClaim(userIdJwtToken);
        this.userAuthorizationItems.ScopesDelimitedString = this.getDelimitedScopes(userIdJwtToken).toString();
        // Since we currently do not store the full user name in Cognito, we will be extracting it from the email address:
        this.userAuthorizationItems.DisplayName = "";
        try {
            this.userAuthorizationItems.DisplayName = this.userAuthorizationItems.UserName.substr(0, this.userAuthorizationItems.UserName.indexOf('.')) + " " + this.userAuthorizationItems.UserName.substr(this.userAuthorizationItems.UserName.indexOf('.') + 1, this.userAuthorizationItems.UserName.indexOf('@') - (this.userAuthorizationItems.UserName.indexOf('.') + 1));
            this.userAuthorizationItems.Initials = this.userAuthorizationItems.UserName.substr(0, 1) + this.userAuthorizationItems.UserName.substr(this.userAuthorizationItems.UserName.indexOf('.') + 1, 1);
        }
        catch (ex) { }
        ;
        // Disabled 2020-07-30 by MANWIT:
        // Fake the role for now
        // this.userAuthorizationItems.DisplayRole = "Coordinator";
        // Since we are now using this library generically, we have to revert to a basic role, while we work on implementing different roles
        // this.userAuthorizationItems.DisplayRole = "Normal User";
    };
    // Get user name
    /**
     *
     * @remarks Returns the email address component from the JWT token as user name
     *
     * @param userIdJwtToken A decoded JWT token (use a JS library such as jwt_decode to decode a JWT)
     *
     * @returns Returns the email address component from the JWT token as user name
     */
    symfrjs.prototype.getUserNameFromEmailClaim = function (userIdJwtToken) {
        // console.log('getUserNameFromEmailClaim()');
        return userIdJwtToken.email;
    };
    // Get user ID
    /**
     *
     * @remarks Returns the user id from the JWT token
     *
     * @param userIdJwtToken A decoded JWT token (use a JS library such as jwt_decode to decode a JWT)
     *
     * @returns Returns the user id from the JWT
     */
    symfrjs.prototype.getUserId = function (userIdJwtToken) {
        // console.log('getUserId()');
        try {
            // Modified 2020/03/23 by MANWIT: an identity only really exists when federating with Symetra SSO (or another federated IDP) 
            return userIdJwtToken.identities[0].userId;
        }
        catch (ex) {
            // Added 2020/03/23 by MANWIT: in case of a ForgeRock realm user (user credentials login flow as opposed to SSO) we need to get the userId from another attribute
            return userIdJwtToken.sub;
        }
    };
    // Get the scopes from the token
    /**
     *
     * @remarks Returns the group membership from the token
     *
     * @param userIdJwtToken A decoded JWT token (use a JS library such as jwt_decode to decode a JWT)
     *
     * @returns Returns the group membership from the JWT
     */
    symfrjs.prototype.getDelimitedScopes = function (userIdJwtToken) {
        //  console.log('getDelimitedGroups()');
        // Added 2020/03/23 by MANWIT: if user is not part of any groups, return empty array!
        try {
            if (typeof userIdJwtToken["scopes"] !== "undefined") {
                return userIdJwtToken["scopes"];
            }
            else {
                return "";
            }
        }
        catch (ex) {
            return "";
        }
    };
    /**
     * @remarks Logs out current user and clears the token from memory and local storage
     *
     * @returns Nothing
     */
    symfrjs.prototype.logout = function (next) {
        //  console.log('Logging out user and clearing tokens');
        this.userAuthorizationItems = null;
        this._userIdTokensPublicKey = null;
        window.localStorage.removeItem('userAuthorizationItems');
        if (typeof next !== "undefined") {
            next();
        }
    };
    /**
     * @remarks Call this function when you want to have a cookie returned that allows you to hit protected static assets in a CloudFront distribution (as long as that distribution is deployed with the same base domain as the where you are calling this function from)
     *
     * @returns Nothing
     */
    symfrjs.prototype.createSignedCookie = function (next) {
        //console.log('createSignedCookie()');
        var accessToken = this.getAuthHeaderToken();
        // console.log('accessToken = ' + accessToken);
        var options = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": accessToken
            },
            method: "GET",
            // Added 2020-06-16 by MANWIT: otherwise the cookies returned will not be set in the browser!!!
            credentials: 'include',
            mode: 'cors'
        };
        // Now make a call to /createsignedcookie. This will write the cookies in the browser IF the domain of this page matches the
        // domain of this endpoint!! (that is why they have to be subdomains of the same domain) 
        fetch(this.authConfig.CreateSignedCookieEndpointURL, options).then(function (response) {
            // Disabled 2020-06-10 by MANWIT: cause compat issues with IE11
            // For debugging only
            try {
                for (var _i = 0, _a = response["headers"]["entries"](); _i < _a.length; _i++) {
                    var pair = _a[_i];
                    // The cloudfront Set-Cookie headers should be visible in this collection now
                    // console.log(pair[0] + ': ' + pair[1]);
                }
            }
            catch (ex) {
                console.log(ex);
            }
            ;
            // comment for debugging purposes
            console.log('CreateSignedCookie response = ' + JSON.stringify(response));
            return response;
        }).then(function (body) {
            return body.text();
        }).then(function (body) {
            // comment for debugging purposes
            console.log('createSignedCookie() body: ' + body);
            next(null);
        }).catch(function (error) {
            console.log('createSignedCookie() error = ' + JSON.stringify(error));
            next(error);
        });
    };
    /**
     * @remarks Call this function to temporary store the Authentication Token (including IdToken, RefreshToken, etc.) in a temporary cookie to retrieve it later using readShortLivedAuthCookies()
     * Remember, both the scripts calling writeShortLivedAuthCookies() and readShortLivedAuthCookies() need to run under the same base domain for the cookie to be accessible by readShortLivedAuthCookies()
     *
     * @returns Nothing
     */
    symfrjs.prototype.writeShortLivedAuthCookies = function () {
        var _this = this;
        var idToken = this.userAuthorizationItems.UserIdToken;
        var refreshToken = this.userAuthorizationItems.RefreshToken;
        var accessToken = this.userAuthorizationItems.AccessToken;
        var writeShortLivedCookie = function (name, value) {
            var newCookie = name + "=" + value + ";max-age=120;domain=" + _this.authConfig.CommonDomain;
            // console.log('newCookie: ' + newCookie);
            document.cookie = newCookie;
            // console.log('Storing cookie: ' + JSON.stringify(document.cookie));
        };
        writeShortLivedCookie("access_token", accessToken);
        writeShortLivedCookie("id_token", idToken);
        writeShortLivedCookie("refresh_token", refreshToken);
    };
    /**
     * @remarks Call this function to read the cookie containing the Authentication Token (including IdToken, RefreshToken, etc.) stored by writeShortLivedAuthCookies()
     * Remember, both the scripts calling writeShortLivedAuthCookies() and readShortLivedAuthCookies() need to run under the same base domain for the cookie to be accessible by readShortLivedAuthCookies()
     *
     * @returns Nothing
     */
    symfrjs.prototype.readShortLivedAuthCookies = function (next) {
        var _this = this;
        var accessToken = null;
        var idToken = null;
        var refreshToken = null;
        //console.log(' document.cookie BEFORE = ' + document.cookie);
        var readShortLivedCookie = function (name) {
            var value = null;
            document.cookie.split(';').some(function (cookie) {
                if (cookie.trim().startsWith(name)) {
                    // console.log('Reading cookie = ' + name);
                    try {
                        value = cookie.split("=")[1].split(";")[0];
                        if (value.length > 0) {
                            try {
                                var commonDomain = _this.authConfig.CommonDomain;
                                // console.log('commonDomain = ' + commonDomain);
                                // Now, immediately delete the cookie so it can't be reused
                                var deleteCookie = name + "=; domain=" + commonDomain + "; expires=Thu, 01 Jan 1970 00:00:00 UTC; max-age=0; path=/";
                                //console.log('deleteCookie: ' + deleteCookie);
                                document.cookie = deleteCookie;
                            }
                            catch (ex) {
                                console.log('Error deleting domain cookie ' + name + '. authConfig isn\'t loaded yet.');
                            }
                            ;
                            // Now, immediately delete the cookie so it can't be reused
                            var deleteCookie2 = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; max-age=0; path=/";
                            //console.log('deleteCookie2: ' + deleteCookie2);
                            document.cookie = deleteCookie2;
                            // console.log('Cookie ' + name + ' value = ' + value);
                        }
                    }
                    catch (ex) {
                        value = null;
                    }
                    ;
                }
                return false;
            });
            return value;
        };
        accessToken = readShortLivedCookie("access_token");
        idToken = readShortLivedCookie("id_token");
        refreshToken = readShortLivedCookie("refresh_token");
        // console.log(' document.cookie AFTER = ' + document.cookie);
        // console.log('accessToken = ' + accessToken);
        // console.log('idToken = ' + idToken);
        // console.log('refreshToken = ' + refreshToken);
        if (accessToken !== null && idToken !== null && refreshToken !== null) {
            //  console.log('this.userAuthorizationItems = ' + JSON.stringify(this.userAuthorizationItems));
            // Inject the 3 tokens into the UserAuthService
            this.userAuthorizationItems = {};
            this.userAuthorizationItems.AccessToken = accessToken;
            this.userAuthorizationItems.UserIdToken = idToken;
            this.userAuthorizationItems.RefreshToken = refreshToken;
            // console.log('this.userAuthorizationItems = ' + JSON.stringify(this.userAuthorizationItems));
            this.processNewTokens(function (tokenIsValid) {
                // Success, do nothing, just continue
                // console.log('Tokens read from cookies picked up by processNewTokens()');
                if (typeof next !== "undefined") {
                    next(null);
                }
            }, function (error) {
                //window.alert('Access denied: ' + error);
                if (typeof next !== "undefined") {
                    next("Tokens from cookies were invalid");
                }
            });
        }
        else {
            if (typeof next !== "undefined") {
                next("No tokens found in cookies");
            }
        }
    };
    /**
    * @remarks This function is called to delete the short lived Authentication Token from the cookies to avoid misuse
    *
    * @returns Nothing
    */
    symfrjs.prototype.deleteShortLivedAuthCookies = function () {
        var _this = this;
        var deleteShortLivedCookie = function (name) {
            var value = null;
            document.cookie.split(';').some(function (cookie) {
                if (cookie.trim().startsWith(name)) {
                    // console.log('Reading cookie = ' + name);
                    try {
                        value = cookie.split("=")[1].split(";")[0];
                        if (value.length > 0) {
                            try {
                                var commonDomain = _this.authConfig.CommonDomain;
                                // console.log('commonDomain = ' + commonDomain);
                                // Now, immediately delete the cookie so it can't be reused
                                var deleteCookie = name + "=; domain=" + commonDomain + "; expires=Thu, 01 Jan 1970 00:00:00 UTC; max-age=0; path=/";
                                //    console.log('deleteCookie: ' + deleteCookie);
                                document.cookie = deleteCookie;
                            }
                            catch (ex) {
                                console.log('Error deleting domain cookie ' + name + '. authConfig isn\'t loaded yet.');
                            }
                            ;
                            // Now, immediately delete the cookie so it can't be reused
                            var deleteCookie2 = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; max-age=0; path=/";
                            // console.log('deleteCookie2: ' + deleteCookie2);
                            document.cookie = deleteCookie2;
                            //  console.log('Cookie ' + name + ' value = ' + value);
                        }
                    }
                    catch (ex) {
                        value = null;
                    }
                    ;
                }
                return false;
            });
            return value;
        };
        deleteShortLivedCookie("access_token");
        deleteShortLivedCookie("id_token");
        deleteShortLivedCookie("refresh_token");
    };
    return symfrjs;
}());
export { symfrjs };
