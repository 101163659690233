var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import fetch from 'node-fetch';
import * as symcogjs from '../js/symcogjs_v2/symcogjs';
import * as PKCE from '../js/symcogjs_v2/pkce';
import * as symfrjs from '../js/symfrjs/symfrjs';
var oauthState = null;
var oauthStateParameterReplacementRegex = /(state=)([{0}\d.]*)([&\s])/gmi;
var oauthCodeChallenge = null;
var oauthCodeVerifier = null;
var oauthCodeChallengeParameterReplacementRegex = /(code_challenge=)([{0}\dABCDEF]*)([&\s])/gmi;
var randomizeOAuthState = function () {
    console.log('randomizeOAuthState()');
    oauthState = (Math.random() * new Date().getMilliseconds()).toString();
    window.localStorage.setItem('OAuthState', oauthState);
};
var generateOAuthCodeChallenge = function () {
    console.log('generateOAuthCodeChallenge()');
    var pkceChallenge = new PKCE.PKCE();
    var challenge = pkceChallenge.pkceChallenge();
    console.log('challenge = ' + JSON.stringify(challenge));
    oauthCodeChallenge = challenge["code_challenge"];
    oauthCodeVerifier = challenge["code_verifier"];
    window.localStorage.setItem('OAuthCodeChallenge', oauthCodeChallenge);
    window.localStorage.setItem('OAuthCodeVerifier', oauthCodeVerifier);
    if (!pkceChallenge.verifyChallenge(oauthCodeVerifier, oauthCodeChallenge)) {
        console.log('PKCE Challenge mismatch.');
    }
    else {
        console.log('PKCE Challenge matches.');
    }
};
var setAuthConfigs = function (authConfig, idpParam) {
    if (typeof authConfig["IdentityProviders"] !== "undefined" && authConfig["IdentityProviders"] !== null && typeof idpParam !== "undefined" && idpParam !== null && typeof authConfig["IdentityProviders"][idpParam] !== "undefined" && authConfig["IdentityProviders"][idpParam] !== null) {
        var federationName = authConfig["IdentityProviders"][idpParam]["Name"];
        authConfig["SSOLoginUrl"] = authConfig["IdentityProviders"][idpParam]["SSOLoginUrl"];
        authConfig["RedirectUrl"] = authConfig["IdentityProviders"][idpParam]["RedirectUrl"];
        config["ProtectedCloudFrontDomainName"] = authConfig["IdentityProviders"][idpParam]["ProtectedCloudFrontDomainName"];
        if (typeof authConfig["IdentityProviders"][idpParam]["LogoutUrl"] !== "undefined" && authConfig["IdentityProviders"][idpParam]["LogoutUrl"] != null) {
            authConfig["LogoutUrl"] = authConfig["IdentityProviders"][idpParam]["LogoutUrl"];
            authConfig["LogoutUrl"] = authConfig["LogoutUrl"].replace(oauthStateParameterReplacementRegex, "$1" + oauthState + "$3");
            authConfig["LogoutUrl"] = authConfig["LogoutUrl"].replace(oauthCodeChallengeParameterReplacementRegex, "$1" + oauthCodeChallenge + "$3");
        }
        console.log('Identified the IdP as ' + federationName);
    }
    authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthStateParameterReplacementRegex, "$1" + oauthState + "$3");
    authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthCodeChallengeParameterReplacementRegex, "$1" + oauthCodeChallenge + "$3");
};
var writeIdpCookie = function (idpParam) {
    document.cookie = "idp=" + idpParam;
};
var writeRelCodeCookie = function (relCode) {
    document.cookie = "relCode=" + relCode;
};
var readIdpCookie = function () {
    var cookie = document.cookie.match('(^|;)\\s*' + 'idp' + '\\s*=\\s*([^;]+)');
    return cookie ? cookie.pop() : '';
};
var readRelCodeCookie = function () {
    var cookie = document.cookie.match('(^|;)\\s*' + 'relCode' + '\\s*=\\s*([^;]+)');
    return cookie ? cookie.pop() : '';
};
var cachePermissions = function (token) { return __awaiter(void 0, void 0, void 0, function () {
    var options, res, data, code, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                options = {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": token
                    },
                    method: "GET",
                };
                return [4 /*yield*/, fetch(authConfig["CachePermissionsEndpointURL"], options)];
            case 1:
                res = _a.sent();
                return [4 /*yield*/, res.text()];
            case 2:
                data = _a.sent();
                code = JSON.parse(data);
                console.log("leveraged CachePermissionsEndpointURL: ".concat(authConfig["CachePermissionsEndpointURL"]));
                console.log("Parsed cachePermissions code: ".concat(JSON.stringify(code)));
                if (code.body.relationshipCode != null) {
                    return [2 /*return*/, code.body.relationshipCode.toString()];
                }
                return [2 /*return*/, null];
            case 3:
                error_1 = _a.sent();
                console.log(error_1);
                return [2 /*return*/, error_1];
            case 4: return [2 /*return*/];
        }
    });
}); };
var redirectDelayInMS = 3000;
try {
    if (typeof window.localStorage.getItem('OAuthState') !== "undefined" && window.localStorage.getItem('OAuthState') !== null) {
        oauthState = window.localStorage.getItem('OAuthState');
    }
    else {
        randomizeOAuthState();
    }
}
catch (ex) {
}
try {
    if (typeof window.localStorage.getItem('OAuthCodeChallenge') !== "undefined" && window.localStorage.getItem('OAuthCodeChallenge') !== null && window.localStorage.getItem('OAuthCodeChallenge') !== 'null') {
        oauthCodeChallenge = window.localStorage.getItem('OAuthCodeChallenge');
        if (typeof window.localStorage.getItem('OAuthCodeVerifier') !== "undefined" && window.localStorage.getItem('OAuthCodeVerifier') !== null) {
            oauthCodeVerifier = window.localStorage.getItem('OAuthCodeVerifier');
        }
    }
    else {
        generateOAuthCodeChallenge();
    }
}
catch (ex) {
    console.log('PKCE error: ' + ex);
}
console.log("localStorage: ".concat(JSON.stringify(window.localStorage)));
var authConfig;
var config = {
    authConfig: authConfig
};
var options = {
    headers: {
        "Content-Type": "application/json"
    },
    method: "GET",
    credentials: 'include'
};
var configFile;
var userAuthService;
if (window.location.href.indexOf('localhost') !== -1) {
    userAuthService = new symcogjs.symcogjs();
    configFile = 'js/config-dev-fr.json';
}
else if (window.location.href.indexOf('COG') !== -1) {
    configFile = 'js/config-dev.json';
    userAuthService = new symcogjs.symcogjs();
}
else if (window.location.href.indexOf('FR') !== -1) {
    configFile = 'js/config-dev-fr.json';
    userAuthService = new symfrjs.symfrjs();
}
else if (window.location.href.indexOf('.dev.') !== -1) {
    configFile = 'js/config-dev.json';
    userAuthService = new symcogjs.symcogjs();
}
else if (window.location.href.indexOf('.int.') != -1) {
    configFile = 'js/config-int.json';
    userAuthService = new symcogjs.symcogjs();
}
else {
    configFile = 'js/config-prod.json';
    userAuthService = new symcogjs.symcogjs();
}
console.log("current config file: ".concat(configFile, "; current userAuthService = ").concat(userAuthService));
fetch(configFile, options).then(function (body) {
    return body.json();
}).then(function (result) {
    config = result;
    authConfig = result["Auth"];
    console.log("authConfig from file: ".concat(configFile, " =") + JSON.stringify(authConfig));
    var urlParams = new URLSearchParams(window.location.search);
    console.log('urlParams =' + urlParams);
    var idpParam;
    idpParam = urlParams.get('idp');
    idpParam ? writeIdpCookie(idpParam) : idpParam = readIdpCookie();
    setAuthConfigs(authConfig, idpParam);
    userAuthService.updateAuthConfig(authConfig);
    if (urlParams.has('logout') && urlParams.get('logout') == '1') {
        randomizeOAuthState();
        generateOAuthCodeChallenge();
        userAuthService.logout(function () {
            setTimeout(function () {
                if (typeof authConfig["LogoutUrl"] !== "undefined" && authConfig["LogoutUrl"] != null) {
                    authConfig["LogoutUrl"] = authConfig["LogoutUrl"].replace(oauthStateParameterReplacementRegex, "$1" + oauthState + "$3");
                    authConfig["LogoutUrl"] = authConfig["LogoutUrl"].replace(oauthCodeChallengeParameterReplacementRegex, "$1" + oauthCodeChallenge + "$3");
                    console.log('About to redirect to: ' + authConfig["LogoutUrl"]);
                    console.log(authConfig);
                    document.location.href = authConfig["LogoutUrl"];
                }
                else {
                    console.log('About to redirect to: ' + authConfig["SSOLoginUrl"]);
                    document.location.href = authConfig["SSOLoginUrl"];
                }
            }, redirectDelayInMS);
        });
        return;
    }
    var err = "";
    if (urlParams.has('code')) {
        var authorizationCode = urlParams.get('code');
        var state = urlParams.get('state');
        if (state !== "undefined" && state != null && state.length > 0 && state != oauthState) {
            err = "No access. OAuth state mismatch. (5)";
            randomizeOAuthState();
            authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthStateParameterReplacementRegex, "$1" + oauthState + "$3");
            authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthCodeChallengeParameterReplacementRegex, "$1" + oauthCodeChallenge + "$3");
            setTimeout(function () {
                document.location.href = 'error.html?redirect=' + encodeURIComponent(authConfig["SSOLoginUrl"]) + '&err=' + encodeURIComponent(err);
            }, redirectDelayInMS);
            return;
        }
        var verifier = oauthCodeVerifier;
        if (verifier !== "undefined" && verifier != null && verifier.length > 0 && verifier != oauthCodeVerifier) {
            err = "No access. Verifier mismatch. (6)";
            generateOAuthCodeChallenge();
            authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthStateParameterReplacementRegex, "$1" + oauthState + "$3");
            authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthCodeChallengeParameterReplacementRegex, "$1" + oauthCodeChallenge + "$3");
            setTimeout(function () {
                document.location.href = 'error.html?redirect=' + encodeURIComponent(authConfig["SSOLoginUrl"]) + '&err=' + encodeURIComponent(err);
            }, redirectDelayInMS);
            return;
        }
        userAuthService.authorizeUser(authorizationCode, function () {
            return __awaiter(this, void 0, void 0, function () {
                var accessToken, relCode;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            accessToken = userAuthService.getAuthHeaderToken();
                            return [4 /*yield*/, cachePermissions(accessToken)];
                        case 1:
                            relCode = _a.sent();
                            writeRelCodeCookie(relCode);
                            if (!authConfig['UnauthorizedDistributors'].includes(relCode)) {
                                userAuthService.createSignedCookie(function (err) {
                                    if (err != null) {
                                        err += " (1)";
                                        randomizeOAuthState();
                                        authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthStateParameterReplacementRegex, "$1" + oauthState + "$3");
                                        authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthCodeChallengeParameterReplacementRegex, "$1" + oauthCodeChallenge + "$3");
                                        document.location.href = 'error.html?redirect=' + encodeURIComponent(authConfig["SSOLoginUrl"]) + '&err=' + encodeURIComponent(err);
                                        console.log('About to redirect to: ' + authConfig["SSOLoginUrl"]);
                                    }
                                    else {
                                        userAuthService.writeShortLivedAuthCookies();
                                        setTimeout(function () {
                                            var protectedCloudFrontDomainName = config["ProtectedCloudFrontDomainName"] + (config["ProtectedCloudFrontDomainName"].indexOf('?') == -1 ? '?' : '&') + "t=" + (new Date().getTime());
                                            console.log('(1) Navigating to: ' + protectedCloudFrontDomainName);
                                            document.location.href = protectedCloudFrontDomainName;
                                        }, redirectDelayInMS);
                                    }
                                });
                            }
                            else {
                                err += " (4)";
                                randomizeOAuthState();
                                authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthStateParameterReplacementRegex, "$1" + oauthState + "$3");
                                authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthCodeChallengeParameterReplacementRegex, "$1" + oauthCodeChallenge + "$3");
                                document.location.href = 'error2.html?redirect=' + encodeURIComponent(authConfig["SSOLoginUrl"]) + '&err=' + encodeURIComponent(err);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }, function (err) {
            err += " (2)";
            randomizeOAuthState();
            authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthStateParameterReplacementRegex, "$1" + oauthState + "$3");
            authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthCodeChallengeParameterReplacementRegex, "$1" + oauthCodeChallenge + "$3");
            document.location.href = 'error.html?redirect=' + encodeURIComponent(authConfig["SSOLoginUrl"]) + '&err=' + encodeURIComponent(err);
            console.log('About to redirect to: ' + authConfig["SSOLoginUrl"]);
        }, verifier);
    }
    else {
        userAuthService.isUserAuthorized(true, function () {
            console.log('The user is still logged on, even after a page refresh');
            console.log("localStorage: ".concat(JSON.stringify(window.localStorage)));
            var relCode = readRelCodeCookie();
            if (!authConfig['UnauthorizedDistributors'].includes(relCode)) {
                userAuthService.createSignedCookie(function (err) {
                    if (err != null) {
                        err += " (3)";
                        randomizeOAuthState();
                        authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthStateParameterReplacementRegex, "$1" + oauthState + "$3");
                        authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthCodeChallengeParameterReplacementRegex, "$1" + oauthCodeChallenge + "$3");
                        document.location.href = 'error.html?redirect=' + encodeURIComponent(authConfig["SSOLoginUrl"]) + '&err=' + encodeURIComponent(err);
                    }
                    else {
                        userAuthService.writeShortLivedAuthCookies();
                        setTimeout(function () {
                            console.log('(3) Navigating to: ' + config["ProtectedCloudFrontDomainName"]);
                            document.location.href = config["ProtectedCloudFrontDomainName"];
                            console.log("++++ OAuth after ".concat(window.localStorage.getItem('OAuthState')));
                        }, redirectDelayInMS);
                    }
                });
            }
            else {
                randomizeOAuthState();
                authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthStateParameterReplacementRegex, "$1" + oauthState + "$3");
                authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthCodeChallengeParameterReplacementRegex, "$1" + oauthCodeChallenge + "$3");
                document.location.href = 'error2.html?redirect=' + encodeURIComponent(authConfig["SSOLoginUrl"]) + '&err=' + encodeURIComponent(err);
            }
        }, function () {
            console.log('About to redirect to: ' + authConfig["SSOLoginUrl"]);
            randomizeOAuthState();
            authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthStateParameterReplacementRegex, "$1" + oauthState + "$3");
            authConfig["SSOLoginUrl"] = authConfig["SSOLoginUrl"].replace(oauthCodeChallengeParameterReplacementRegex, "$1" + oauthCodeChallenge + "$3");
            document.location.href = authConfig["SSOLoginUrl"];
        });
    }
}).catch(function (error) {
    console.log('fetch authConfig error = ' + error);
});
