/****************************************************************************************************
 *
 *  Name: pkce
 *  Purpose: Helper Functions for implementing PKCE (Proof Key for Code Exchange)
 *
 ****************************************************************************************************/
import * as CryptoJS from 'crypto-js';
var PKCE = /** @class */ (function () {
    function PKCE() {
    }
    PKCE.prototype.random = function (length, mask) {
        var result = "";
        var randomIndices = new Int8Array(length);
        // Modified 2020-08-11 by MANWIT: IE11 compat!!
        var cryptoObj = window.crypto || window["msCrypto"]; // for IE 11
        cryptoObj.getRandomValues(randomIndices);
        var byteLength = 256;
        var maskLength = Math.min(mask.length, byteLength);
        var scalingFactor = byteLength / maskLength;
        for (var i = 0; i < length; i++) {
            result += mask[Math.floor(Math.abs(randomIndices[i]) / scalingFactor)];
        }
        return result;
    };
    /** Generate cryptographically secure random string
     */
    PKCE.prototype.generateRandomString = function () {
        var mask = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-._~";
        return this.random(128, mask);
    };
    /** Base64 url encode a string
     * @param {string} base64 The base64 string to url encode
     * @returns {string} The base64 url encoded string
     */
    PKCE.prototype.base64UrlEncode = function (string) {
        return this.base64Encode(string).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
    };
    PKCE.prototype.base64Encode = function (string) {
        return string.toString(CryptoJS.enc.Base64);
    };
    /** Generate a PKCE challenge verifier
     * @param {number} length Length of the verifier
     * @returns {string} A random verifier `length` characters long
     */
    PKCE.prototype.generateVerifier = function () {
        return this.generateRandomString();
    };
    /** Generate a PKCE challenge code from a verifier
     * @param {string} code_verifier
     * @returns {string} The base64 url encoded code challenge
     */
    PKCE.prototype.generateChallenge = function (code_verifier) {
        var hash = CryptoJS.SHA256(code_verifier);
        return this.base64UrlEncode(hash);
    };
    /** Generate a PKCE challenge pair
     * @returns {{code_challenge:string,code_verifier:string}} PKCE challenge pair
     */
    PKCE.prototype.pkceChallenge = function () {
        var verifier = this.generateVerifier();
        var challenge = this.generateChallenge(verifier);
        return {
            code_challenge: challenge,
            code_verifier: verifier
        };
    };
    /** Verify that a code_verifier produces the expected code challenge
     * @param {string} code_verifier
     * @param {string} expectedChallenge The code challenge to verify
     * @returns {boolean} True if challenges are equal. False otherwise.
     */
    PKCE.prototype.verifyChallenge = function (code_verifier, expectedChallenge) {
        var actualChallenge = this.generateChallenge(code_verifier);
        return actualChallenge === expectedChallenge;
    };
    return PKCE;
}());
export { PKCE };
